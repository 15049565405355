<template>
  <div>
    <button v-if="showButton" @click="scrollToTop" class="scroll-to-top">
      <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37.5 65.625C53.033 65.625 65.625 53.033 65.625 37.5C65.625 21.967 53.033 9.375 37.5 9.375C21.967 9.375 9.375 21.967 9.375 37.5C9.375 53.033 21.967 65.625 37.5 65.625Z" fill="#1090CB"/>
        <path d="M27.5566 35.7249L37.5003 25.7812L47.4439 35.7249" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.5 49.2188V25.7812" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showButton = window.scrollY > 300;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 200px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: transparent;
}
</style>